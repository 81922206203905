
































































import { Vue, Prop, Component } from 'vue-property-decorator';
import fromUnixTime from 'date-fns/fromUnixTime';
import Qs from 'qs';
import {Charge} from "@/repositories/company/payment-repository";

@Component({
  filters: {
    fromUnixTime
  }
})
export default class extends Vue {
  private charge:Charge = null as any;

  private created() {
    this.charge = new Charge(Qs.parse(location.search.substring(1)) as any);
  }
  private mounted() {
    window.print();
  }

  private shouldShowModelor(): boolean {
    return this.charge.createdAt < (new Date(2024, 7, 1)); //2024-08-01にModelorからスマショクに切り替え
  }
  private get companyInfo(): { name: string; invoiceNumber: string; } {
    return this.shouldShowModelor() ?
      {
        name: '株式会社Modelor',
        invoiceNumber: 'T8130001051170'
      } :
      {
        name: '株式会社スマショク',
        invoiceNumber: 'T5130001076773'
      };
  }

}
